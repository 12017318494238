import React from "react";
import { Input } from "../../ui/input";
import InputDatePicker from "../date-picker";
import { BiltyInvoiceDetailsCardProps } from "../cards/bilty-invoice-details-card";

export default function BiltyInvoiceDetailsForm() {
  const inputClasses: string =
    "w-full bg-neutral-50 border border-neutral-200 text-neutral-800";

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data: BiltyInvoiceDetailsCardProps = {
      invoiceNumber: formData.get("invoiceNumber") as string,
      invoiceDate: new Date(formData.get("invoiceDate") as string),
      billNumber: formData.get("billNumber") as string,
      generationDate: new Date(formData.get("generationDate") as string),
      expiryDate: new Date(formData.get("expiryDate") as string),
    };
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-[56rem] space-y-4">
      <h3 className="font-semibold text-base">Freight Details</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="space-y-4">
          <Input
            type="text"
            required
            name="invoiceNumber"
            placeholder="Invoice Number"
            className={inputClasses}
          />
          <Input
            type="number"
            required
            name="billNumber"
            placeholder="E-Way Bill Number"
            className={inputClasses}
          />
          <InputDatePicker name="expiryDate" placeholder="Expiry Date" />
        </div>
        <div className="space-y-4">
          <InputDatePicker name="invoiceDate" placeholder="Invoice Date" />
          <InputDatePicker
            name="generationDate"
            placeholder="Generation Date"
          />
        </div>
      </div>
    </form>
  );
}
