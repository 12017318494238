import { Paperclip } from "lucide-react";
import BusinessProfileParent from "../../../components/app/business-profile-parent";
import { Label } from "../../../components/ui/label";
import { ChevronRight } from "lucide-react";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import { Link } from "react-router-dom";
import React from "react";

function BusinessProfile1() {
  // todo : add input and logo state management here

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData.entries());
    console.log(data);
  }
  return (
    <BusinessProfileParent>
      <div className="w-full sm:h-[70vh] flex justify-center items-center p-5 pt-0 sm:pt-5">
        <div className="bg-cardinal-300/30 bg-opacity-45 p-6 rounded-lg max-w-sm md:max-w-md w-full">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-lg font-bold">Business Profile</h2>
            <p className="text-cardinal-500 font-medium">
              <span className="text-xl sm:text-2xl">1</span>
              <span className="text-neutral-900">/3</span>
              <div className="text-xs text-neutral-900">Completed</div>
            </p>
          </div>
          <div className="mb-8">
            <div className="flex items-center gap-4">
              <Label
                htmlFor="business-logo"
                className="w-16 h-16 cursor-pointer rounded-full border-2 bg-white border-cardinal-400 flex items-center justify-center mb-2"
              >
                <Paperclip className="w-6 h-6 text-cardinal-400" />
              </Label>
              <p className="text-neutral-600 text-sm">
                Upload your business Logo
              </p>
            </div>
            <input className="hidden" id="business-logo" type="file" />
          </div>

          <div className="mb-6">
            <h3 className=" font-bold text-cardinal-600 mb-4">
              Basic Information
            </h3>
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div className="">
                <Label className="text-xs text-neutral-600">
                  Contact Person
                </Label>
                <Input
                  type="text"
                  name="contactPerson"
                  placeholder="Contact Person"
                  className="w-full rounded-md border bg-white shadow-none border-neutral-200 focus:outline-none focus:ring-2 focus:ring-cardinal-400"
                />
              </div>
              <div>
                <Label className="text-xs text-neutral-600">
                  Contact Number
                </Label>
                <Input
                  type="tel"
                  name="contactNumber"
                  placeholder="Contact Number"
                  className="w-full rounded-md border bg-white shadow-none border-neutral-200 focus:outline-none focus:ring-2 focus:ring-cardinal-400"
                />
              </div>
              <div>
                <Label className="text-xs text-neutral-600">
                  Alternative Number
                </Label>
                <Input
                  type="tel"
                  name="alternativeNumber"
                  placeholder="Alternative Number"
                  className="w-full rounded-md border bg-white shadow-none border-neutral-200 focus:outline-none focus:ring-2 focus:ring-cardinal-400"
                />
              </div>

              <div className="flex justify-end gap-5 items-center mt-6">
              <Link to="/">
                  <Button
                    variant="link"
                    type="button"
                    className="text-neutral-900"
                  >
                    Skip
                  </Button>
              </Link>
                <Link to="/signup/verification/profile2">
                  <Button
                    type="submit"
                    className="bg-cardinal-500 flex items-center text-white px-6 py-2 rounded-md hover:bg-cardinal-600 transition-colors"
                  >
                    <span>Next</span>
                    <ChevronRight className="ml-2 w-4 h-4" />
                  </Button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </BusinessProfileParent>
  );
}

export default BusinessProfile1;
