import { useState } from "react";
import BiltyTruckDetailsCard, {
  BiltyTruckDetailsCardProps,
} from "../../components/app/cards/bilty-truck-details-card";
import { BiltyStatus } from "../../components/app/cards/bilty-card";
import BiltyProgressBarCard, {
  BiltyProgressDetailsBarProps,
} from "../../components/app/cards/bilty-progress-bar-card";
import BiltyInvoiceDetailsCard, {
  BiltyInvoiceDetailsCardProps,
} from "../../components/app/cards/bilty-invoice-details-card";
import BiltyConsignorConsigneeCard from "../../components/app/cards/bilty-consignor-consignee-card";
import BiltyItemDetailsCard, {
  ItemDetailsCardProps,
} from "../../components/app/cards/bilty-item-details-card";
import BiltyFrightDetailsCard from "../../components/app/cards/bilty-fright-details-card";
import { ChevronDown, ChevronRight } from "lucide-react";

const BiltyDetailsPage = () => {
  const [isConsignmentDetailsExpanded, setIsConsignmentDetailsExpanded] =
    useState<boolean>(false);

  const toggleConsignmentDetailsExpanded = () => {
    setIsConsignmentDetailsExpanded((prev) => !prev);
  };

  const biltyData: BiltyProgressDetailsBarProps[] = [
    {
      orderId: "ORD1001",
      biltyStatus: BiltyStatus.Pending,
      from: "Ahmedabad",
      to: "Mumbai",
      biltyDate: new Date("2024-10-01T10:00:00Z"),
    },
    {
      orderId: "ORD1002",
      biltyStatus: BiltyStatus.Progress,
      from: "Bengaluru",
      to: "Chennai",
      biltyDate: new Date("2024-10-05T12:30:00Z"),
    },
    {
      orderId: "ORD1003",
      biltyStatus: BiltyStatus.Delivered,
      from: "Hyderabad",
      to: "Delhi",
      biltyDate: new Date("2024-10-10T15:45:00Z"),
    },
  ];

  const biltyData1 = biltyData[0];

  const biltyTruckDetails: BiltyTruckDetailsCardProps = {
    vehicleNumber: "MH12AB1234",
    driverName: "Rajesh Kumar",
    driverNumber: "+91 98765 43210",
    licenseNumber: "MH1234567890123",
    fleetOwner: "Speedy Logistics Pvt Ltd",
    fleetMobile: "+91 99876 54321",
    gstNumber: "27AAECS1234F1Z5",
  };

  const biltyInvoiceDetails: BiltyInvoiceDetailsCardProps = {
    invoiceNumber: "INV20240001",
    invoiceDate: new Date("2024-10-01T00:00:00Z"),
    billNumber: "BIL20240001",
    generationDate: new Date("2024-10-02T00:00:00Z"),
    expiryDate: new Date("2024-10-31T00:00:00Z"),
  };

  const itemDetails: ItemDetailsCardProps[] = [
    {
      itemName: "Masala Chai",
      packagingType: "Packet",
      quantity: 100,
      weight: 250,
      weightUnit: "grams",
      amount: 199.99,
      noOfArticle: 2,
    },
    {
      itemName: "Basmati Rice",
      packagingType: "Bag",
      quantity: 5,
      weight: 1000,
      weightUnit: "grams",
      amount: 299.0,
      noOfArticle: 2,
    },
  ];

  const frightDetail = {
    frightType: "Standard", // Example freight type
    amount: 10, // Example amount
    pricePerUnit: 750.0, // Example price per unit in INR
    advance: 3000.0,
    remaining: 4500.0,
  };

  // add spme logic to fetch bilty lr no to show that on ui

  // fetch the commission amount and replace the 0 with the fetched amount
  const commissionAmount = 8000;
  const advanceAllConsignment = 120000;
  const totalAllConsignment = 1000;
  return (
    <div className="w-full px-4 mb-10">
      <div>
        <h3 className="sm:text-lg font-bold">
          {/* add that fetched bilty lr number here then */}
          LR Bilty No: RLB-5122
        </h3>
        <div className="w-full flex justify-center items-center my-4">
          <BiltyProgressBarCard {...biltyData1} />
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-5">
        <div>
          <h3 className="sm:text-lg font-bold">Truck Details</h3>
          <div className="w-full my-4">
            <BiltyTruckDetailsCard {...biltyTruckDetails} />
          </div>
        </div>
        <div>
          <h3 className="sm:text-lg font-bold">Invoice & E-Way Bill Details</h3>
          <div className="w-full my-4">
            <BiltyInvoiceDetailsCard {...biltyInvoiceDetails} />
          </div>
        </div>
        <div>
          <h3 className="sm:text-lg font-bold">Consignment Details</h3>
          <div className="my-4 p-3  border rounded-md">
            <div
              className="flex items-center justify-between"
              onClick={toggleConsignmentDetailsExpanded}
            >
              <h3 className="font-semibold">Consignor and Consignee Details</h3>
              {isConsignmentDetailsExpanded ? (
                <ChevronDown
                  size={16}
                  className="text-neutral-600 transform transition-all duration-200 ease-in-out rotate-0"
                />
              ) : (
                <ChevronRight
                  size={16}
                  className="text-neutral-600 transform transition-all duration-200 ease-in-out -rotate-90"
                />
              )}
            </div>
            {isConsignmentDetailsExpanded && (
              <div className="w-full p-3 space-y-3">
                {/* the props are not passed on here fetch the data inside the card component and then pass it into it's two sub cards component */}
                <BiltyConsignorConsigneeCard />
                <div className="space-y-3">
                  <h3 className="font-semibold">Items Details</h3>
                  <div className="grid lg:grid-cols-2 gap-3">
                    {itemDetails.map((item, index) => (
                      <BiltyItemDetailsCard key={index} {...item} />
                    ))}
                  </div>
                </div>
                <h3 className="font-semibold">Items Details</h3>
                <BiltyFrightDetailsCard {...frightDetail} />
              </div>
            )}
          </div>
        </div>
        <div className="space-y-4">
          <div className="space-y-4">
            <h3 className="sm:text-lg font-bold">Bilty Commission</h3>
            <div className="border p-2 font-semibold bg-neutral-50 rounded-md">
              ₹{commissionAmount}
            </div>
          </div>
          <div className="space-y-4">
            <h3 className="sm:text-lg font-bold">Bilty Commission</h3>
            <div className="border space-y-3 bg-neutral-50 rounded-md p-2 text-xs sm:text-sm">
              <div className="flex gap-2 justify-between items-center">
                <p className="text-neutral-400">Total (All Consignment)</p>
                <p className="font-semibold">₹{totalAllConsignment}</p>
              </div>
              <div className="flex gap-2 justify-between items-center">
                <p className="text-neutral-400">Advance (All Consignment)</p>
                <p className="font-semibold">₹ {advanceAllConsignment}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default BiltyDetailsPage;