export interface BiltyInvoiceDetailsCardProps {
  invoiceNumber: string;
  invoiceDate: Date;
  billNumber: string;
  generationDate: Date;
  expiryDate: Date;
}

export default function BiltyInvoiceDetailsCard({
  ...props
}: BiltyInvoiceDetailsCardProps) {
  return (
    <div className="p-3 border shadow-sm rounded-md flex flex-col justify-between gap-2 text-xs ">
      <div className="flex w-full justify-between items-center text-xs ">
        <div className="space-y-1">
          <p className="text-neutral-400 text-xs">INVOICE NUMBER</p>
          <p className="font-semibold">{props.invoiceNumber}</p>
        </div>
        <div className="space-y-1">
          <p className="text-neutral-400 text-xs">INVOICE DATE</p>
          <p className="font-semibold">
            {props.invoiceDate.getDate()}-
            {props.invoiceDate.toLocaleString("default", { month: "short" })}-
            {props.invoiceDate.getFullYear()}
          </p>
        </div>
      </div>
      <div className="flex w-full justify-between items-center">
        <div className="space-y-1">
          <p className="text-neutral-400 text-xs">E-WAY BILL NUMBER</p>
          <p className="font-semibold">{props.invoiceNumber}</p>
        </div>
        <div className="space-y-1">
          <p className="text-neutral-400 text-xs">GENERATION DATE</p>
          <p className="font-semibold text-right">
            {props.generationDate.getDate()}-
            {props.generationDate.toLocaleString("default", { month: "short" })}
            -{props.generationDate.getFullYear()}
          </p>
        </div>
      </div>
      <div className="flex w-full justify-between items-center">
        <div className="space-y-1">
          <p className="text-neutral-400 text-xs">EXPIRY DATE</p>
          <p className="font-semibold">
            {props.expiryDate.getDate()}-
            {props.expiryDate.toLocaleString("default", { month: "short" })}-
            {props.expiryDate.getFullYear()}
          </p>
        </div>
      </div>
    </div>
  );
}
