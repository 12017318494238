import { BiltyStatus } from "./bilty-card";

export interface BiltyProgressDetailsBarProps {
  orderId: string;
  biltyStatus: BiltyStatus;
  from: string;
  to: string;
  biltyDate: Date;
}

export default function BiltyProgressBarCard({
  ...props
}: BiltyProgressDetailsBarProps) {
  return (
    <div className="p-3 border shadow rounded-lg flex flex-col justify-between gap-2 text-xs sm:text-sm w-full max-w-xl">
      <div className="flex w-full justify-between gap-2 items-center">
        <div>
          <p>
            <span className="text-neutral-400">Order Id:</span> #{props.orderId}
          </p>
        </div>
        <div>
          <p className="text-neurtal-600">
            <span className="text-neutral-400">Bilty Date:</span>{" "}
            {props.biltyDate.getDate()}{" "}
            {props.biltyDate.toLocaleString("default", { month: "short" })}{" "}
            {props.biltyDate.getFullYear()}
          </p>
        </div>
      </div>
      <div className="flex flex-col px-4">
        <div className="relative">
          <img src="/home/bilty-card.svg" alt="bilty" className="w-full" />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
            <p className="bg-purple-600 rounded-md py-0.5 text-xs text-white px-2">
              {props.biltyStatus}
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between mt-2">
          <p className="font-bold -mx-3">{props.from}</p>
          <p className="font-bold -mx-3">{props.to}</p>
        </div>
      </div>
    </div>
  );
}
