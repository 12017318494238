import React, { useState, useEffect, useRef } from "react";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import { CircleX } from "lucide-react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

function BiltyTruckDetailsModal({ isOpen, onClose }: ModalProps) {
  const [formData, setFormData] = useState({
    vehicleNumber: "",
    driverName: "",
    driverNumber: "",
    licenseNumber: "",
    fleetOwner: "",
    gstNumber: "",
  });
  // might add fleetMobile: "" in the future because that is present in the card component in figma

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleInputDelete = (inputName: keyof typeof formData) => {
    setFormData({ ...formData, [inputName]: "" });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(formData);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md animate-in fade-in duration-200"
        ref={modalRef}
      >
        <form className="space-y-3 p-5" onSubmit={handleSubmit}>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Vehicle Number
            </Label>
            <div className="relative w-full">
              <Input
                required
                name="vehicleNumber"
                value={formData.vehicleNumber}
                onChange={handleInputChange}
                type="text"
                placeholder="Vehicle Number"
                className=" w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800 "
              />
              {formData.vehicleNumber !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => handleInputDelete("vehicleNumber")}
                />
              )}
            </div>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Driver Name
            </Label>
            <div className="relative w-full">
              <Input
                required
                name="driverName"
                value={formData.driverName}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter Driver Name"
                className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
              />
              {formData.driverName !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => handleInputDelete("driverName")}
                />
              )}
            </div>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Driver Number
            </Label>
            <div className="relative w-full">
              <Input
                required
                name="driverNumber"
                value={formData.driverNumber}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter Driver Number"
                className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
              />
              {formData.driverNumber !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => handleInputDelete("driverNumber")}
                />
              )}
            </div>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              License Number
            </Label>
            <div className="relative w-full">
              <Input
                required
                name="licenseNumber"
                value={formData.licenseNumber}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter License Number"
                className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
              />
              {formData.licenseNumber !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => handleInputDelete("licenseNumber")}
                />
              )}
            </div>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Fleet Owner
            </Label>
            <div className="relative w-full">
              <Input
                required
                name="fleetOwner"
                value={formData.fleetOwner}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter Owner Name"
                className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
              />
              {formData.fleetOwner !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => handleInputDelete("fleetOwner")}
                />
              )}
            </div>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              GST Number
            </Label>
            <div className="relative w-full">
              <Input
                required
                name="gstNumber"
                value={formData.gstNumber}
                onChange={handleInputChange}
                type="text"
                placeholder="Enter GST Number"
                className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
              />
              {formData.gstNumber !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => handleInputDelete("gstNumber")}
                />
              )}
            </div>
          </div>
          <div className="flex justify-center items-center pt-4">
            <Button
              className="bg-cardinal-600 w-24 sm:w-full sm:max-w-[15rem] hover:bg-cardinal-600"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default BiltyTruckDetailsModal;
