import React, { useState } from "react";
import {
  AlertCircle,
  ChevronLeft,
  ChevronRight,
} from "lucide-react";
import BusinessProfileParent from "../../../components/app/business-profile-parent";
import { Input } from "../../../components/ui/input";
import { Label } from "../../../components/ui/label";
import { Button } from "../../../components/ui/button";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IoDocumentText } from "react-icons/io5";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

const BusinessProfile2 = () =>  {
  const [formData, setFormData] = useState({
    businessName: "Shadowbox Logistic",
    website: "",
    email: "",
    aadhar: "",
    businessDocument: "",
    preferredCities: [],
  });

  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  // todo: add logic here

  // const handleFileUpload = (name: File | string) => {
  //   // Handle file upload logic here
  //   console.log(`Uploading file for ${name}`);
  // };
  return (
    <BusinessProfileParent>
      <div className="w-full h-[90vh] sm:h-[70vh] flex justify-center items-center p-5 pt-0 sm:pt-5 my-10 ">
        <div className="bg-cardinal-300/30 bg-opacity-45 p-6 rounded-lg max-w-sm md:max-w-md w-full">
          <div className="flex justify-between items-center mb-2">
            <h2 className="text-lg font-bold">Business Profile</h2>
            <p className="text-cardinal-500 font-medium">
              <span className="text-xl sm:text-2xl">2</span>
              <span className="text-neutral-900">/3</span>
              <div className="text-xs text-neutral-900">Completed</div>
            </p>
          </div>

          <div className="space-y-4">
            <h3 className="text-cardinal-500 font-medium mb-4">
              Document Info
            </h3>

            <div className="space-y-1">
              <div>
                <Label className="text-xs text-neutral-600">
                  Business Name
                </Label>
                <Input
                  type="text"
                  name="businessName"
                  placeholder="Business Name"
                  value={formData.businessName}
                  onChange={handleInputChange}
                  className="w-full bg-white rounded-md border shadow-none border-neutral-200 focus:outline-none focus:ring-2 focus:ring-cardinal-400"
                />
              </div>
              <div>
                <Label className="text-xs text-neutral-600">Website</Label>
                <Input
                  type="url"
                  name="website"
                  placeholder="Website"
                  value={formData.website}
                  onChange={handleInputChange}
                  className="w-full bg-white rounded-md border shadow-none border-neutral-200 focus:outline-none focus:ring-2 focus:ring-cardinal-400"
                />
              </div>

              <div>
                <Label className="text-xs text-neutral-600">Email</Label>
                <div className="relative">
                  <Input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full bg-white rounded-md border shadow-none border-neutral-200 focus:outline-none focus:ring-2 focus:ring-cardinal-400"
                  />
                  <button
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-cardinal-500 text-sm"
                    onClick={() => console.log("Verify email")}
                  >
                    Verify
                  </button>
                </div>
              </div>

              <div>
                <p className="text-sm font-bold text-neutral-700 mb-1">
                  Click here to upload
                </p>
                <p className="text-xs text-neutral-400 mb-2">
                  Files supported (JPG,PNG,PDF Maximum size 5MB)
                </p>
                <div className="relative w-full">
                  <Input
                    type="file"
                    className="w-full rounded-md border border-neutral-200 bg-white cursor-pointer opacity-0 absolute"
                    id="file-input"
                  />
                  <Label
                    htmlFor="file-input"
                    className="flex items-center justify-between border border-neutral-200 bg-white rounded-md p-2 cursor-pointer"
                  >
                    <span className="text-neutral-400 flex items-center">
                      <IoDocumentText className="w-4 h-4 text-neutral-400/50 mr-1" />{" "}
                      Business Document
                    </span>
                    <AlertCircle size={18} className="text-red-500" />
                  </Label>
                </div>
              </div>
              <div>
                <Label className="text-xs text-neutral-600">Aadhar</Label>
                <div className="relative">
                  <Input
                    type="text"
                    name="aadhar"
                    placeholder="Aadhar"
                    value={formData.aadhar}
                    onChange={handleInputChange}
                    className="w-full  bg-white rounded-md border shadow-none border-neutral-200 focus:outline-none focus:ring-2 focus:ring-cardinal-400"
                  />
                  <button
                    className="absolute right-3 top-1/2 -translate-y-1/2 text-cardinal-500 text-sm"
                    onClick={() => console.log("Verify Aadhar")}
                  >
                    Verify
                  </button>
                </div>
              </div>
              <div className="w-full">
                <Label className="text-xs text-neutral-600">State</Label>
                <div className="relative">
                  <Select>
                    <SelectTrigger className="w-full bg-white">
                      <SelectValue placeholder="State" />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value="kolkata">Kolkata</SelectItem>
                      <SelectItem value="shimla">Shimla</SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              </div>

              <p className="text-xs text-neutral-500 flex items-center gap-1">
                <AlertCircle size={14} />
                You can post order for any other city also
              </p>
            </div>

            <div className="flex justify-between items-center mt-6">
              <button
                type="button"
                onClick={handleGoBack}
                className="px-6 py-2 flex items-center bg-cardinal-100 text-cardinal-500 rounded-md"
              >
                <ChevronLeft className="mr-2 w-4 h-4" />
                Back
              </button>
              <Link to="/signup/verification/profile3">
                <Button
                  type="submit"
                  className="bg-cardinal-500 flex items-center text-white px-6 py-2 rounded-md hover:bg-cardinal-600 transition-colors"
                >
                  <span>Next</span>
                  <ChevronRight className="ml-2 w-4 h-4" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </BusinessProfileParent>
  );
}

export default BusinessProfile2;
