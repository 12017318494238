import { Input } from "../../ui/input";

import {
  ConsigneeDetails,
} from "../cards/bilty-consignor-consignee-card";

export default function BiltyConsignorConsigneeForm() {
  return (
    <div className="my-4 grid sm:grid-cols-2 gap-3">
      <ConsigneeForm />
      <ConsignorForm />
    </div>
  );
}

function ConsigneeForm() {
  const inputClasses: string =
    "w-full bg-neutral-50 border border-neutral-200 text-neutral-800";

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data: ConsigneeDetails = {
      consignee: formData.get("consignee") as string,
      gstNumber: formData.get("gstNumber") as string,
      mobile: formData.get("mobile") as string,
      address: formData.get("address") as string,
    };
    console.log(data);
  };

  return (
    <form className="space-y-2 w-full" onSubmit={handleSubmit}>
      <h3 className="font-semibold sm:text-lg">Consignor</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <Input
          type="text"
          required
          name="consignee"
          placeholder="Consignee"
          className={inputClasses}
        />
        <Input
          type="text"
          required
          name="mobileNumber"
          placeholder="Mobile Number"
          className={inputClasses}
        />
        <Input
          type="text"
          required
          name="gstNumber"
          placeholder="GST Number"
          className={inputClasses}
        />
        <Input
          type="text"
          required
          name="address"
          placeholder="Address"
          className={inputClasses}
        />
      </div>
      {/* <div className="flex justify-center sm:justify-end">
        <Button
          className="bg-cardinal-600 w-full max-w-[12rem] hover:bg-cardinal-600 text-white"
          type="submit"
        >
          Submit
        </Button>
      </div> */}
    </form>
  );
}

function ConsignorForm() {
  const inputClasses: string =
    "w-full bg-neutral-50 border border-neutral-200 text-neutral-800";

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data: ConsigneeDetails = {
      consignee: formData.get("consignee") as string,
      gstNumber: formData.get("gstNumber") as string,
      mobile: formData.get("mobile") as string,
      address: formData.get("address") as string,
    };
    console.log(data);
  };

  return (
    <form className="space-y-2 w-full" onSubmit={handleSubmit}>
      <h3 className="font-semibold sm:text-lg">Consignor</h3>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
        <Input
          type="text"
          required
          name="consignor"
          placeholder="Consignor"
          className={inputClasses}
        />
        <Input
          type="text"
          required
          name="mobileNumber"
          placeholder="Mobile Number"
          className={inputClasses}
        />
        <Input
          type="text"
          required
          name="gstNumber"
          placeholder="GST Number"
          className={inputClasses}
        />
        <Input
          type="text"
          required
          name="address"
          placeholder="Address"
          className={inputClasses}
        />
      </div>
      {/* <div className="flex justify-center sm:justify-end">
        <Button
          className="bg-cardinal-600 w-full max-w-[12rem] hover:bg-cardinal-600 text-white"
          type="submit"
        >
          Submit
        </Button>
      </div> */}
    </form>
  );
}
