import { Separator } from "../../ui/separator";

export interface BiltyCreateConsignmentDetailsCardProps {
  consignor: string;
  cosignee: string;
  totalItems: number;
  remaining: number;
}

export default function BiltyCreateConsignmentDetailsCard({
  ...props
}: BiltyCreateConsignmentDetailsCardProps) {
  return (
    <div className="p-2 space-y-1 bg-white rounded-md shadow text-xs max-w-xs">
      <div className="space-y-1">
        <p className="text-neutral-400">CONSIGNOR</p>
        <p className="font-medium">{props.consignor}</p>
      </div>
      <Separator className="bg-neutral-200" />
      <div className="space-y-1">
        <p className="text-neutral-400">CONSIGNEE</p>
        <p className="font-medium">{props.cosignee}</p>
      </div>
      <Separator className="bg-neutral-200" />
      <div className="flex items-center gap-10 justify-between">
        <div className="space-y-1">
          <p className="text-neutral-400">TOTAL ITEMS</p>
          <p className="font-medium">{props.totalItems}</p>
        </div>
        <div className="space-y-1">
          <p className="text-neutral-400">REMAINING</p>
          <p className="font-medium">{props.totalItems}</p>
        </div>
      </div>
    </div>
  );
}
