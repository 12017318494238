import React, { useEffect, useRef } from "react";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { Label } from "../../ui/label";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "../../ui/select";
import { ModalProps } from "../../../lib/definitions";

export interface BiltyEditItemProps {
  itemName: string;
  packagingType: string;
  quantity: number;
  weight: number;
  noOfArticle: number;
  weightUnit: string;
  amount: number;
}

export interface EditItemDetailsModalProps
  extends BiltyEditItemProps,
    ModalProps {}

// pass all the props you need to the modal component with their default values
// first default values will be shown in the input fields and then all the values will be updated by the user

export default function BiltyEditItemDetailsModal({
  onClose,
  isOpen,
  ...rest
}: EditItemDetailsModalProps) {
  const modalRef = useRef<HTMLDivElement>(null);

  const packagingTypes = [" box", "bag", "carton", "drum", "pallet", "other"];
  const weightUnits = ["kilo", "litres"];



  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (target.closest('[role="listbox"]')) {
        return;
      }
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // formData api to get the form data // don't remove name fields from the input elements
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData.entries());
    console.log(data);
    onClose();
  };

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        ref={modalRef}
        className="bg-white rounded-lg w-full max-w-md shadow-lg p-3 text-sm sm:text-base"
      >
        {/* <div className="flex justify-center items-center">
          <h3 className="text-base sm:text-lg">
            Edit Item Details
          </h3>
        </div>
        <Separator /> */}
        <form className="space-y-3 p-5" onSubmit={handleSubmit}>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Item Name
            </Label>
            <Input
              required
              name="itemName"
              type="text"
              defaultValue={rest.itemName}
              placeholder="Enter Item Name"
              className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
            />
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Packaging Type
            </Label>
            <Select name="packagingType">
              <SelectTrigger className="w-full bg-neutral-50 border-neutral-200">
                <SelectValue placeholder="Select Packaging Type" />
                <SelectContent>
                  {packagingTypes.map((type) => (
                    <SelectItem
                      key={type}
                      value={type}
                      defaultValue={rest.packagingType}
                    >
                      {type}
                    </SelectItem>
                  ))}
                </SelectContent>
              </SelectTrigger>
            </Select>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              No. of Article
            </Label>
            <Input
              required
              name="noOfArticle"
              defaultValue={rest.noOfArticle}
              type="text"
              placeholder="Enter Article Count"
              className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
            />
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Total Weight
            </Label>
            <div className="flex w-full">
              <Input
                required
                name="totalWeight"
                type="text"
                defaultValue={rest.weight}
                placeholder="Enter Total Weight"
                className="w-full bg-neutral-50 border-r-0 rounded-r-none border-neutral-200 text-sm text-neutral-800"
              />
              <Select required name="weightUnit">
                <SelectTrigger className="rounded-l-none bg-neutral-50 border-neutral-200 max-w-24">
                  <SelectValue
                    placeholder="kilo-litres"
                    className=" bg-neutral-50 rounded-l-none border-neutral-200 max-w-24"
                  />
                </SelectTrigger>
                <SelectContent className="rounded-l-none bg-neutral-50 border-neutral-200 max-w-24">
                  {weightUnits.map((quantity) => (
                    <SelectItem
                      key={quantity}
                      value={quantity}
                      defaultValue={rest.weightUnit}
                      className="bg-neutral-50 border-neutral-200"
                    >
                      {quantity}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Fright/QTL
            </Label>
            <Input
              required
              name="frightQtl"
              type="text"
              defaultValue={rest.quantity}
              placeholder="Enter Fright/QTL"
              className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
            />
          </div>
          <div>
            <Label className="text-xs sm:text-sm text-neutral-700 font-normal">
              Amount
            </Label>
            <Input
              required
              name="amount"
              type="text"
              defaultValue={rest.amount}
              placeholder="Enter Amount"
              className="w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800"
            />
          </div>
          <div className="flex justify-center items-center pt-4">
            <Button
              className="bg-cardinal-600 w-24 sm:w-full sm:max-w-[15rem] hover:bg-cardinal-600"
              type="submit"
            >
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
