export interface ConsignorDetails {
  consignor: string;
  gstNumber: string;
  mobile: string;
  address: string; // this depened how roadlyne is storing address in the backend / db
}

export interface ConsigneeDetails {
  consignee: string;
  gstNumber: string;
  mobile: string;
  address: string;
}

export default function BiltyConsignorConsigneeCard() {
  const consignorData: ConsignorDetails = {
    consignor: "ABC Logistics Pvt Ltd",
    gstNumber: "27AAECA1234F1Z5",
    mobile: "+91 98765 43210",
    address: "123, Industrial Area, Sector 10, Pune, Maharashtra, 411045",
  };

  const consigneeData: ConsigneeDetails = {
    consignee: "DEF Enterprises",
    gstNumber: "27AAEFD1234F1Z5",
    mobile: "+91 98765 67890",
    address: "321, Market Street, Delhi, Delhi, 110001",
  };

  return (
    <div className="p-3 rounded-md  grid xl:grid-cols-2 justify-center gap-2 text-xs sm:text-sm">
      <ConsignorDetailsCard {...consignorData} />
      {/* consignee thing goes here */}
      <ConsigneeDetailsCard {...consigneeData} />
    </div>
  );
}

function ConsignorDetailsCard({ ...props }: ConsignorDetails) {
  return (
    <div className="grid sm:grid-cols-2 gap-2 justify-between text-xs p-2 border-b xl:border-r xl:border-b-0">
      <div>
        <p className="text-neutral-400">Consignor</p>
        <p className="font-semibold ">{props.consignor}</p>
      </div>
      <div>
        <p className="text-neutral-400 sm:text-right">GST Number</p>
        <p className="font-semibold sm:text-right ">{props.gstNumber}</p>
      </div>
      <div>
        <p className="text-neutral-400">Mobile Number</p>
        <p className="font-semibold">{props.mobile}</p>
      </div>
      <div>
        <p className="text-neutral-400 sm:text-right">Address</p>
        <p className="font-semibold text-wrap sm:text-right">{props.address}</p>
      </div>
    </div>
  );
}

function ConsigneeDetailsCard({ ...props }: ConsigneeDetails) {
  return (
    <div className="grid sm:grid-cols-2 gap-2 justify-between text-xs p-2 ">
      <div>
        <p className="text-neutral-400">Consignee</p>
        <p className="font-semibold ">{props.consignee}</p>
      </div>
      <div>
        <p className="text-neutral-400 sm:text-right">GST Number</p>
        <p className="font-semibold sm:text-right ">{props.gstNumber}</p>
      </div>
      <div>
        <p className="text-neutral-400">Mobile Number</p>
        <p className="font-semibold">{props.mobile}</p>
      </div>
      <div>
        <p className="text-neutral-400 sm:text-right">Address</p>
        <p className="font-semibold text-wrap sm:text-right">{props.address}</p>
      </div>
    </div>
  );
}
