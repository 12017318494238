export interface BiltyTruckDetailsCardProps {
  vehicleNumber: string;
  driverName: string;
  driverNumber: string;
  licenseNumber: string;
  fleetOwner: string;
  fleetMobile: string;
  gstNumber: string;
}

export default function BiltyTruckDetailsCard({
  ...props
}: BiltyTruckDetailsCardProps) {
  return (
    <div className="p-3 border shadow-sm rounded-md flex flex-col justify-between gap-2 text-xs ">
      <div className="flex w-full justify-between items-center text-xs ">
        <div className="space-y-1">
          <p className="text-neutral-400 text-xs">VEHICLE NUMBER</p>
          <p className="font-semibold">{props.vehicleNumber}</p>
        </div>
        <div className="space-y-1">
          <p className="text-neutral-400 text-xs">DRIVER NAME</p>
          <p className="font-semibold">{props.driverName}</p>
        </div>
      </div>
      <div className="flex w-full justify-between items-center">
        <div className="space-y-1">
          <p className="text-neutral-400 text-xs">DRIVER NUMBER</p>
          <p className="font-semibold">{props.driverNumber}</p>
        </div>
        <div className="space-y-1">
          <p className="text-neutral-400 text-xs">LICENSE NAME</p>
          <p className="font-semibold">{props.driverName}</p>
        </div>
      </div>
      <div className="flex w-full justify-between items-center">
        <div className="space-y-1">
          <p className="text-neutral-400 text-xs">FLEET OWNER</p>
          <p className="font-semibold">{props.fleetOwner}</p>
        </div>
        <div className="space-y-1">
          <p className="text-neutral-400 text-xs text-right">FLEET MOBILE</p>
          <p className="font-semibold">{props.fleetMobile}</p>
        </div>
      </div>
      <div className="flex w-full justify-between items-center">
        <div className="space-y-1">
          <p className="text-neutral-400 text-xs">GST NUMBER</p>
          <p className="font-semibold">{props.gstNumber}</p>
        </div>
      </div>
    </div>
  );
}
