import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Input } from "../components/ui/input";
import KhataAmountDetailsCard, {
  KhataPaymentDetailsProps,
} from "../components/app/cards/khata-amount-details-card";
import KhataHistoryCard, {
  KhataHistoryCardProps,
  KhataPaymentStatus,
} from "../components/app/cards/khata-history-card";
import { Button } from "../components/ui/button";
import KhataAddAccountModal from "../components/app/modals/khata-add-account-modal";
import KhataAccountDetailsCard, {
  KhataAccountDetailsProps,
} from "../components/app/cards/khata-account-details-card";
import KhataPaymentStatusCard from "../components/app/cards/khata-payment-status-card";
import KhataToPayModal from "../components/app/modals/khata-to-pay-modal";
import KhataToRecieveModal from "../components/app/modals/Khata-to-recieve-modal";
import clsx from "clsx";
import { Undo2Icon } from "lucide-react";


const KhataPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [showKhataToPayModal, setShowKhataToPayModal] =
    useState<boolean>(false);
  const [showAddAccountModal, setShowAddAccountModal] =
    useState<boolean>(false);
  const [showKhataToRecieveModal, setShowKhataToRecieveModal] =
    useState<boolean>(false);

  // for small screens only
  const [isSmallScreen, setIsSmallScreen] = useState<boolean>(false);
  const [showAccountDetails, setShowAccountDetails] = useState<boolean>(false);

  useEffect(() => {
    function handleResize() {
      // because of the md grid layout { (md:grid-cols-2): breakpoint }
      const isSmall = window.innerWidth < 768;
      setIsSmallScreen(isSmall);
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const khataPayments: KhataPaymentDetailsProps[] = [
    {
      bussinessName: "Shree Ganesh Electronics",
      paymentStatus: KhataPaymentStatus.ToPay,
      amount: 15000,
    },
    {
      bussinessName: "Delhi Spice Restaurant",
      paymentStatus: KhataPaymentStatus.ToAdvance,
      amount: 5000,
    },
    {
      bussinessName: "Kumar Textiles",
      paymentStatus: KhataPaymentStatus.ToPay,
      amount: 23000,
    },
  ];

  const khataAccountDetail: KhataAccountDetailsProps = {
    name: "Rajesh Kumar",
    bussinessName: "Shree Ganesh Electronics",
    mobileNumber: "9876543210",
  };

  const khataHistory: KhataHistoryCardProps[] = [
    {
      orderId: "ORD123456",
      date: new Date("2024-10-15"),
      amount: "5000",
      paymentStatus: KhataPaymentStatus.ToPay,
      reason: "Monthly payment for supplies",
    },
    {
      orderId: "ORD123457",
      date: new Date("2024-10-20"),
      amount: "7500",
      paymentStatus: KhataPaymentStatus.ToAdvance,
      reason: "Advance payment for bulk order",
    },
  ];

  const toggleKhataToPayModal = () => {
    setShowKhataToPayModal((prev) => !prev);
  };

  const toggleKhatToRecieveModal = () => {
    setShowKhataToRecieveModal((prev) => !prev);
  };

  const toggleAddAccountModal = () => {
    setShowAddAccountModal((prev) => !prev);
  };

  const toggleAccountDetails = () => {
    setShowAccountDetails((prev) => !prev);
  };

  return (
    // used md here so
    <div className="w-full px-5 grid md:grid-cols-2 gap-10 sm:gap-2">
      <div
        className={clsx(
          `space-y-2 min-h-[70vh] mb-5 sm:mb-0 flex flex-col md:border-r sm:pr-4`,
          {
            hidden: showAccountDetails && isSmallScreen,
          },
        )}
      >
        {/* get give card */}
        <div className="flex justify-between gap-5 w-full">
          <div className="p-3 border flex flex-col gap-2 justify-center items-center rounded-lg w-full max-w-[15rem] text-sm sm:text-base">
            <p className="text-center text-green-600 font-bold">₹ 0</p>
            <p className="text-neutral-400">You will get</p>
          </div>
          <div className="flex justify-end w-full">
            <div className="p-3 border flex flex-col text-sm sm:text-base gap-2 justify-center items-center rounded-lg w-full max-w-[15rem]">
              <p className="text-center text-cardinal-600 font-bold">₹ 0</p>
              <p className="text-neutral-400">You will give</p>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <Input
            type="text"
            value={searchParams.get("search") || ""}
            onChange={(e) => setSearchParams({ search: e.target.value })}
            className="w-full text-sm sm:text-base shadow-none border-neutral-200 rounded-lg"
            placeholder="Search"
          />
        </div>
        <div>
          <h3 className="font-bold sm:text-lg my-4">Amount</h3>
          <div className="flex flex-col space-y-3">
            {khataPayments.map((payment, index) => (
              // that small screen toggle to show accoutn details functionality using onClick state handler
              <div
                key={index}
                onClick={toggleAccountDetails}
                className="cursor-pointer"
              >
                <KhataAmountDetailsCard {...payment} />
              </div>
            ))}
          </div>
        </div>
        <div className="flex-1 flex justify-center items-end h-full">
          <Button
            className="w-full max-w-xs bg-blue-500 hover:bg-blue-500 text-sm ms:text-base"
            onClick={toggleAddAccountModal}
          >
            Add New Account
          </Button>
        </div>
      </div>
      <div
        className={clsx(
          "md:flex space-y-2 md:space-y-0 md:gap-3  min-h-[70vh] sm:pl-2 md:flex-col",
          {
            hidden: !showAccountDetails,
          },
        )}
      >
        <div
          className={clsx("md:hidden ", {
            flex: showAccountDetails,
          })}
        >
          <Button
            variant="outline"
            className="rounded-full mb-3 items-center font-normal py-1"
            onClick={toggleAccountDetails}
          >
            <Undo2Icon size={12} />
            Back
          </Button>
        </div>
        <div className="w-full">
          <KhataAccountDetailsCard {...khataAccountDetail} />
        </div>
        <div className="space-y-2">
          {khataPayments.map((payment, index) => (
            <KhataPaymentStatusCard {...payment} key={index} />
          ))}
        </div>
        <div>
          <h3 className="font-bold sm:text-lg my-4">History</h3>
          <div className="space-y-2">
            {khataHistory.map((history, index) => (
              <KhataHistoryCard {...history} key={index} />
            ))}
          </div>
          <div className="flex-1 flex justify-center my-5 items-center h-full w-full">
            <div className="flex gap-2 justify-between w-full">
              <Button
                className="w-full max-w-xs bg-cardinal-600 hover:bg-cardinal-600 text-sm ms:text-base"
                onClick={toggleKhataToPayModal}
              >
                TO PAY
              </Button>
              <Button
                className="w-full max-w-xs bg-green-600 hover:bg-green-600 text-sm ms:text-base"
                onClick={toggleKhatToRecieveModal}
              >
                RECIEVE
              </Button>
            </div>
          </div>
        </div>
      </div>
      <KhataAddAccountModal
        isOpen={showAddAccountModal}
        onClose={toggleAddAccountModal}
      />
      <KhataToPayModal
        isOpen={showKhataToPayModal}
        onClose={toggleKhataToPayModal}
      />
      <KhataToRecieveModal
        isOpen={showKhataToRecieveModal}
        onClose={toggleKhatToRecieveModal}
      />
    </div>
  );
}
export default KhataPage;