import { Button } from "../ui/button";
import { FcGoogle } from "react-icons/fc";
import { BsApple, BsTwitterX } from "react-icons/bs";
import { MdOutlineFacebook } from "react-icons/md";

function OAuthComponent() {
  return (
    <div className="w-full grid sm:grid-cols-2 gap-2">
      <Button
        variant="outline"
        className="bg-white/10 flex item-center opacity-95 border border-neutral-300 text-xs"
      >
        <FcGoogle className="w-5 h-5" />
        Sign Up with Google
      </Button>
      <Button
        variant="outline"
        className="bg-white/10 flex item-center opacity-95 border border-neutral-300 text-xs"
      >
        <BsApple className="w-5 h-5" />
        Sign Up with Apple
      </Button>
      <Button
        variant="outline"
        className="bg-white/10 flex item-center opacity-95 border border-neutral-300 text-xs"
      >
        <BsTwitterX className="w-4 h-4" />
        Sign Up with X
      </Button>
      <Button
        variant="outline"
        className="bg-white/10 flex item-center opacity-95 border border-neutral-300 text-xs"
      >
        <MdOutlineFacebook className="w-6 h-6 text-blue-600" />
        Sign Up with Facebook
      </Button>
    </div>
  );
}

export default OAuthComponent;
