import { ModalProps } from "../../../lib/definitions";
import React, { useEffect, useRef, useState } from "react";
import { Label } from "../../ui/label";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import InputDatePicker from "../date-picker";
import { CircleX } from "lucide-react";
import { KhataHistoryCardProps } from "../cards/khata-history-card";

export type KhataEditHistoryProps = Omit<
  KhataHistoryCardProps,
  "paymentStatus" | "orderId"
>;

export interface khataEditHistoryModalProps
  extends ModalProps,
    KhataEditHistoryProps {}

export default function KhataEditHistoryModal({
  isOpen,
  onClose,
  ...rest
}: khataEditHistoryModalProps) {
  const [formData, setFromData] = useState<KhataEditHistoryProps>({
    amount: rest.amount,
    date: rest.date,
    reason: rest.reason,
  });

  useEffect(() => {
    function handleClickOutside(e: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
        onClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose]);

  const modalRef = useRef<HTMLDivElement>(null);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFromData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleInputDelete = (inputName: keyof typeof formData) => {
    setFromData({ ...formData, [inputName]: "" });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = new FormData(e.currentTarget);
    const data: KhataEditHistoryProps = {
      ...formData,
      date: new Date(form.get("date") as string),
    };
    console.log(data);
    onClose();
    // const formData = new FormData(e.currentTarget);
    // const data = Object.fromEntries(formData.entries());
  };

  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        className="bg-white rounded-lg shadow-lg w-full max-w-md animate-in fade-in duration-200 p-5"
        ref={modalRef}
      >
        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <Label htmlFor="amount" className="text-xs">
              Enter Amount
            </Label>
            <div className="relative w-full">
              <Input
                required
                name="amount"
                value={formData.amount}
                onChange={handleInputChange}
                type="text"
                placeholder="Amount"
                className=" w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800 pr-6 "
              />
              {formData.amount !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => handleInputDelete("amount")}
                />
              )}
            </div>
          </div>
          <div className="">
            <Label htmlFor="reaspn" className="text-xs">
              Reason
            </Label>
            <div className="relative w-full">
              <Input
                required
                name="reason"
                value={formData.reason}
                onChange={handleInputChange}
                type="text"
                placeholder="Reason"
                className=" w-full bg-neutral-50 border-neutral-200 text-sm text-neutral-800 pr-6c "
              />
              {formData.reason !== "" && (
                <CircleX
                  className="absolute top-1/2 right-2 -translate-y-1/2 text-neutral-400 hover:text-neutral-600 cursor-pointer w-4 h-4"
                  onClick={() => handleInputDelete("reason")}
                />
              )}
            </div>
          </div>
          <div>
            <Label htmlFor="date" className="text-xs">
              Date
            </Label>
            <InputDatePicker date={rest.date} />
          </div>
          <Button
            type="submit"
            className="w-full bg-cardinal-500 hover:bg-cardinal-600 text-white py-3 rounded-lg"
          >
            Update
          </Button>
        </form>
      </div>
    </div>
  );
}
