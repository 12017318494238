import React from "react";
import { Input } from "../../ui/input";
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from "../../ui/select";
import InputDatePicker from "../date-picker";

export default function BiltyCreateGeneratingForm() {
  const orderIds = ["35121efwe", "12512asdcas", "cscfq3212d1"];

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData.entries());
    console.log(data); // send this data to the backend
  };

  const inputClasses: string = "bg-neutral-50 border border-neutral-200";
  return (
    <form onSubmit={handleSubmit} className="space-y-2 text-sm sm:text-base">
      <Input
        type="text"
        className={inputClasses}
        required
        name="vehicleNumber"
        placeholder="Vehicle Number"
      />
      <InputDatePicker />
      <Input
        type="text"
        required
        className={inputClasses}
        name="from"
        placeholder="From"
      />
      <Select required name="orderId">
        <SelectTrigger className={inputClasses}>
          <SelectValue placeholder="Select Order ID" className={inputClasses} />
          <SelectContent className={inputClasses}>
            {orderIds.map((orderId) => (
              <SelectItem key={orderId} value={orderId}>
                {orderId}
              </SelectItem>
            ))}
          </SelectContent>
        </SelectTrigger>
      </Select>
    </form>
  );
}
