import { Link } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import { Separator } from "../../components/ui/separator";
import SignupForm from "../../components/app/forms/SignupForm";

const SignUpPage = () => {
  return (
    <main
      className="min-h-screen relative bg-cover bg-center"
      style={{
        backgroundImage: "url(/login/image-10.png)",
      }}
    >
      <div className="absolute top-4 right-4">
        <Link to="/">
          <IoCloseOutline className="w-6 h-6 text-neutral-600 cursor-pointer" />
        </Link>
      </div>
      <div className="min-h-screen flex flex-col items-center justify-center space-y-5">
        <SignupForm />
        <Separator className="w-full max-w-lg text-neutral-600" />
        <div className="flex text-xs gap-2">
          <p>Have an account?</p>
          <Link to="/login" className="text-cardinal-700">
            Login
          </Link>
        </div>
      </div>
    </main>
  );
}

export default SignUpPage;
