import { Pencil, Trash2 } from "lucide-react";
import { Button } from "../../ui/button";
import { useState } from "react";
import BiltyEditItemDetailsModal from "../modals/bilty-edit-item-details";
import { useLocation } from "react-router-dom";

export interface ItemDetailsCardProps {
  itemName: string;
  packagingType: string;
  quantity: number;
  weight: number;
  weightUnit: string;
  amount: number;
  noOfArticle: number;
}

// todo: change props types according to your needs
export default function BiltyItemDetailsCard({
  ...props
}: ItemDetailsCardProps) {
  const [showEditItemModal, setShowEditItemModal] = useState<boolean>(false);
  const location = useLocation();

  const toggleModal = () => {
    setShowEditItemModal((prev) => !prev);
  };

  return (
    <div className="rounded-md p-3 h-full min-h-40 border shadow-sm flex flex-col justify-between text-xs sm:text-sm w-full">
      <div className="flex items-center justify-between">
        <div>
          <p className="text-neutral-400 text-xs">Item Name</p>
          <p className="font-semibold">{props.itemName}</p>
        </div>
        <div>
          <p className="text-neutral-400 text-xs">Packaging Type</p>
          <p className="font-semibold text-right  ">{props.packagingType}</p>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-neutral-400 text-xs">Quantity</p>
          <p className="font-semibold">{props.quantity}</p>
        </div>
        <div>
          <p className="text-neutral-400 text-xs text-right">Weight</p>
          <p className="font-semibold">
            {props.weight} {props.weightUnit}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <div>
          <p className="text-neutral-400 text-xs">Amount</p>
          <p className="font-semibold">₹ {props.amount}</p>
        </div>
        {location.pathname === "/bilty/create/consigment" && (
          <div className="flex items-center gap-2">
            <Button
              onClick={toggleModal}
              className="rounded-full p-2.5 flex items-center justify-center text-white bg-blue-500 hover:bg-blue-500 shadow-none"
            >
              <Pencil size={12} />
            </Button>
            <Button className="rounded-full p-2.5 flex items-center justify-center text-white bg-cardinal-600 hover:bg-cardinal-500 shadow-none">
              <Trash2 size={12} />
            </Button>
          </div>
        )}
      </div>
      <BiltyEditItemDetailsModal
        isOpen={showEditItemModal}
        onClose={toggleModal}
        {...props}
      />
    </div>
  );
}
