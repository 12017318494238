export enum LoadStatus {
  Pending = "Pending",
  Completed = "Completed",
  Cancelled = "Cancelled",
}

export interface RecentLoadCardProps {
  orderId: string;
  status: LoadStatus; // change it to enum acc to backend
  from: string;
  to: string;
  vehicleType: string;
  vehicleWeight: string;
  itemType: string;
  pickUp: Date;
}

export default function RecentLoadCard({ ...props }: RecentLoadCardProps) {
  return (
    <div className="rounded-lg border h-48 flex justify-between p-4  shadow-sm">
      <div className="flex flex-col w-full justify-between">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-2">
            <span className="font-medium text-neutral-700 text-sm md:text-base">
              Order ID: {props.orderId}
            </span>
          </div>
          <span className="px-3 py-1 bg-green-600 text-white text-xs rounded-full">
            {props.status || "Completed"}
          </span>
        </div>

        <div className="flex items-center justify-between mt-2">
          <div className="flex items-center gap-2">
            <div className="h-2.5 w-2.5 rounded-full bg-blue-500" />
            <div>
              <p className="text-xs text-neutral-400">From</p>
              <p className="text-xs font-medium">{props.from}</p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <div className="h-2.5 w-2.5 rounded-full bg-green-500" />
            <div>
              <p className="text-xs text-neutral-400">To</p>
              <p className="text-xs font-medium">{props.to}</p>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <div>
            <p className="text-xs text-neutral-400">Vehicle Type</p>
            <p className="text-xs font-medium">{props.vehicleType}</p>
          </div>
          <div>
            <p className="text-xs font-medium">{props.vehicleWeight} Kg</p>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <div>
            <p className="text-xs text-neutral-400">Item Type</p>
            <p className="text-xs font-medium">{props.itemType}</p>
          </div>
          <div>
            <p className="text-xs text-neutral-400">Pickup</p>
            <p className="text-xs font-medium">
              {props.pickUp.getDate()}{" "}
              {props.pickUp.toLocaleString("default", { month: "short" })}{" "}
              {props.pickUp.getFullYear()}
            </p>
          </div>
        </div>
      </div>

      <div className="ml-4">
        <div className="p-2 rounded-lg bg-neutral-100">
          <img
            src="/home/image-60.png"
            alt="Vehicle"
            className="h-16 w-16 object-contain"
          />
        </div>
      </div>
    </div>
  );
}
