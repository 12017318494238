import { Link } from "react-router-dom";
import BiltyCard, {
  BiltyCardProps,
  BiltyStatus,
} from "../components/app/cards/bilty-card";
import RecentLoadCard, {
  LoadStatus,
  RecentLoadCardProps,
} from "../components/app/cards/bilty-recent-load-card";

interface AddCreatePostCardDetails {
  src: string;
  title: string;
  height: number;
  link: string;
}

const Homepage = () => {
  const addCreatePostCardDetails: AddCreatePostCardDetails[] = [
    {
      src: "/home/so-note0.svg",
      title: "Add Khata",
      height: 47,
      link: "/khata",
    },
    {
      src: "/home/create-bilty.svg",
      title: "Create Bilty",
      height: 47,
      link: "/bilty/create",
    },
    {
      src: "/home/so-add-file0.svg",
      title: "Post Load",
      height: 47,
      link: "/loads", //
    },
  ];

  const recentLoadCardsData: RecentLoadCardProps[] = [
    {
      orderId: "ORD001",
      status: LoadStatus.Completed,
      from: "Delhi",
      to: "Chennai",
      vehicleType: "Van",
      vehicleWeight: "2 Tons",
      itemType: "Furniture",
      pickUp: new Date("2024-10-25T09:00:00Z"),
    },
    {
      orderId: "ORD002",
      status: LoadStatus.Cancelled,
      from: "Kolkata",
      to: "Hyderabad",
      vehicleType: "Truck",
      vehicleWeight: "10 Tons",
      itemType: "Machinery",
      pickUp: new Date("2024-10-26T11:30:00Z"),
    },
    {
      orderId: "ORD003",
      status: LoadStatus.Pending,
      from: "Pune",
      to: "Ahmedabad",
      vehicleType: "Lorry",
      vehicleWeight: "8 Tons",
      itemType: "Textiles",
      pickUp: new Date("2024-10-27T14:15:00Z"),
    },
    {
      orderId: "ORD004",
      status: LoadStatus.Cancelled,
      from: "Jaipur",
      to: "Lucknow",
      vehicleType: "Pickup Truck",
      vehicleWeight: "5 Tons",
      itemType: "Groceries",
      pickUp: new Date("2024-10-28T08:45:00Z"),
    },
  ];

  const biltyData: BiltyCardProps[] = [
    {
      lrNumber: "LR123456",
      orders: 3,
      date: new Date("2024-10-01"),
      vehicleNumber: "ABC-1234",
      from: "Warehouse A",
      status: BiltyStatus.Pending,
      to: "Retail Store B",
      fromLocation: "City X",
      toLocation: "City Y",
    },
    {
      lrNumber: "LR789012",
      orders: 5,
      date: new Date("2024-10-15"),
      vehicleNumber: "XYZ-5678",
      from: "Warehouse C",
      status: BiltyStatus.Delivered,
      to: "Customer D",
      fromLocation: "City Z",
      toLocation: "City W",
    },
  ];

  return (
    <div className="w-full space-y-3 mb-10">
      <div className="grid sm:grid-cols-3 items-center w-full gap-4 px-5">
        {addCreatePostCardDetails.map((card: AddCreatePostCardDetails) => (
          <AddCreatePostCard {...card} key={card.src} />
        ))}
      </div>
      <div className="space-y-4 px-5">
        <h3 className="font-bold text-lg sm:text-2xl">Recent Loads</h3>
        <div className="grid gap-3 lg:grid-cols-2 xl:grid-cols-3">
          {recentLoadCardsData.map((load) => (
            <RecentLoadCard {...load} key={load.orderId} />
          ))}
        </div>
      </div>
      <div className="space-y-4 px-5">
        <h3 className="font-bold text-lg sm:text-2xl">Bilty</h3>
        <div className="grid gap-5 md:grid-cols-2">
          {biltyData.map((bilty) => (
            <BiltyCard {...bilty} key={bilty.lrNumber} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Homepage;

function AddCreatePostCard({
  src,
  height = 47,
  title,
  link,
}: AddCreatePostCardDetails) {
  return (
    <Link
      to={link}
      className="px-5 py-2 shadow-md rounded-lg w-full h-full flex items-center justify-center"
    >
      <div className="flex flex-col items-center justify-between h-full w-full">
        <div className="flex-1 flex items-center justify-center">
          <img
            src={src}
            className="object-contain h-16 sm:h-20 md:h-24"
            alt={title}
          />
        </div>
        <p className="text-center font-bold mt-4">{title}</p>
      </div>
    </Link>
  );
}
