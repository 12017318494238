function LogoImage({ height = 160 }: { height: number }) {
  return (
    <div className="flex items-center justify-center">
      <img
        src="/login/roadlyne-logo.png"
        alt="roadlyne logo"
        className={`h-[${height}px]`}
      />
    </div>
  );
}

export default LogoImage;
