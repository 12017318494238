import { useEffect, useRef, useState } from "react";
import { ChevronLeft } from "lucide-react";
import { Switch } from "../../ui/switch";
import { Input } from "../../ui/input";
import { Button } from "../../ui/button";
import { Separator } from "../../ui/separator";

function BiltySettingModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const [settings, setSettings] = useState({
    showConsignor: true,
    showDriver: false,
    showCommission: false,
    showBranch: false,
  });

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);
  // todo: add logic to send data to backend

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData.entries());
    console.log(data);
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-4">
      <div
        className="bg-white rounded-lg w-full max-w-md shadow-lg"
        ref={modalRef}
      >
        <div className="flex items-center p-4 border-b">
          <button
            onClick={onClose}
            className="p-1 hover:bg-neutral-100 rounded-full"
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
          <h2 className="text-xl font-semibold flex-1 text-center mr-6">
            Settings
          </h2>
        </div>
        <form className="p-4 space-y-4" onSubmit={handleSubmit}>
          <div className="space-y-4">
            <Input
              name="branchName"
              type="text"
              placeholder="Branch Name"
              className="w-full bg-neutral-50 border-neutral-200"
            />
            <Input
              placeholder="LR Builty Number"
              name="lrBuiltyNumber"
              type="text"
              className="w-full bg-neutral-50 border-neutral-200"
            />
            <p className="text-xs text-neutral-500 italic">
              *Bilty created on this platform will start from the number you
              mention here
            </p>
          </div>
          <Separator />
          <div className="space-y-4 mt-6">
            <div className="flex items-center justify-between">
              <span className="text-neutral-700 text-sm">
                Show Consignor or Consignee Details
              </span>
              <Switch
                checked={settings.showConsignor}
                onCheckedChange={(checked) =>
                  setSettings((prev) => ({ ...prev, showConsignor: checked }))
                }
                className="data-[state=checked]:bg-cardinal-500 bg-black"
              />
            </div>

            <div className="flex items-center justify-between">
              <span className="text-neutral-700 text-sm">
                Show Driver Details
              </span>
              <Switch
                checked={settings.showDriver}
                onCheckedChange={(checked) =>
                  setSettings((prev) => ({ ...prev, showDriver: checked }))
                }
                className="data-[state=checked]:bg-cardinal-500"
              />
            </div>

            <div className="flex items-center justify-between">
              <span className="text-neutral-700 text-sm">
                Show Commission charges
              </span>
              <Switch
                checked={settings.showCommission}
                onCheckedChange={(checked) =>
                  setSettings((prev) => ({ ...prev, showCommission: checked }))
                }
                className="data-[state=checked]:bg-cardinal-500"
              />
            </div>

            <div className="flex items-center justify-between">
              <span className="text-neutral-700 text-sm">Show Branch Name</span>
              <Switch
                checked={settings.showBranch}
                onCheckedChange={(checked) =>
                  setSettings((prev) => ({ ...prev, showBranch: checked }))
                }
                className="data-[state=checked]:bg-cardinal-500"
              />
            </div>
          </div>
        <div className="p-4 flex justify-center items-center">
          <Button
            className="w-[9rem] bg-cardinal-500 hover:bg-cardinal-600 text-white py-2 rounded-md"
            type="submit"
          >
            Save
          </Button>
        </div>
        </form>
      </div>
    </div>
  );
}

export default BiltySettingModal;
