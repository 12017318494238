import { Outlet } from "react-router-dom";
import Navbar from "./navbar";
import { useState, useEffect } from "react";

export default function MainLayout() {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    function handleResize() {
      setIsLargeScreen(window.innerWidth >= 1024);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <main className="min-h-screen flex">
      <div className="flex flex-col w-full">
        <div className="w-full fixed top-0 bg-white p-2 z-10">
          <Navbar
            isLargeScreen={isLargeScreen}
            setIsLargeScreen={setIsLargeScreen}
          />
        </div>
        <div
          className={`flex-1 w-full mt-[5.5rem] ${isLargeScreen ? "pl-[199px]" : ""}`}
        >
          <Outlet />
        </div>
      </div>
    </main>
  );
}
