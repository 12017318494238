import BiltyCreateGeneratingForm from "../../components/app/forms/bilty-create-generating-form";
import BiltyAddItemModal from "../../components/app/modals/bilty-add-item-modal";
import BiltyTruckDetailsModal from "../../components/app/modals/bilty-truck-details-modal";
import { useState } from "react";
import { Separator } from "../../components/ui/separator";
import { ChevronRight, Plus } from "lucide-react";
import { Input } from "../../components/ui/input";
import BiltyCreateConsignmentDetailsCard, {
  BiltyCreateConsignmentDetailsCardProps,
} from "../../components/app/cards/bilty-create-consignment-details-card";
import { Link } from "react-router-dom";

const CreateBiltyPage = () => {
  const [showTruckDetailsModal, setShowTruckDetailsModal] =
    useState<boolean>(false);

  const [showAddItemModal, setShowAddItemModal] = useState<boolean>(false);

  const toggleTruckDetailsModal = () => {
    setShowTruckDetailsModal((prev) => !prev);
  };

  const toggleAddItemModal = () => {
    setShowAddItemModal((prev) => !prev);
  };

  const inputClasses: string =
    "w-full bg-neutral-50 border border-neutral-200 text-neutral-800";

  const consigmentDetails: BiltyCreateConsignmentDetailsCardProps = {
    consignor: "ABC Logistics Ltd.",
    cosignee: "XYZ Distribution Co.",
    totalItems: 150,
    remaining: 30,
  };

  return (
    <div className="w-full px-5 mb-10">
      <div>
        <h3 className="font-semibold sm:text-lg">Generating</h3>
      </div>
      <div className="pt-3 w-full max-w-xl">
        <BiltyCreateGeneratingForm />
      </div>
      <div className="my-4">
        <Separator />
      </div>
      <div className="space-y-3 text-sm sm:text-base w-full">
        <div
          className="bg-neutral-50 w-full cursor-pointer border border-neutral-200 rounded-md px-3 py-2 max-w-2xl flex items-center justify-between gap-2 text-sm sm:text-base"
          onClick={toggleTruckDetailsModal}
        >
          <p className="font-medium">Truck Details</p>
          <ChevronRight size={16} className="text-neutral-600" />
        </div>
        <div className="bg-neutral-50 border border-neutral-200 w-full rounded-md px-3 py-2 max-w-2xl flex flex-col gap-2 text-sm sm:text-base">
          <Link to="/bilty/create/consigment" className="flex justify-between gap-3">
            <p className="font-medium text-sm sm:text-base truncate">
              Consignment Details
            </p>
            <div className="flex items-center gap-2">
              <div className="px-1 py-0.5 rounded-sm text-xs flex bg-blue-200 items-center">
                <Plus size={16} className="text-neutral-600 " />
                <p className="text-xs ">Consignor & Consignee</p>
              </div>
              <ChevronRight size={16} className="text-neutral-600" />
            </div>
          </Link>
          {/* can make changes in grid here if want to show more than one  */}
          <div className="px-3 py-2">
            <BiltyCreateConsignmentDetailsCard {...consigmentDetails} />
          </div>
        </div>
      </div>
      <div className="w-full my-4 max-w-xl">
        <h3 className="font-semibold sm:text-lg my-3">Bilty Commission</h3>
        <Input
          type="text"
          name="biltyPrice"
          placeholder="Bilty Price"
          className={inputClasses}
        />
      </div>
      <BiltyTruckDetailsModal
        isOpen={showTruckDetailsModal}
        onClose={toggleTruckDetailsModal}
      />
      <BiltyAddItemModal
        isOpen={showAddItemModal}
        onClose={toggleAddItemModal}
      />
      {/*
      {itemDetails.map((item, index) => (
        <ItemDetailsCard key={index} {...item} />
      ))} */}
    </div>
  );
}

export default CreateBiltyPage;
