import React, { useEffect, useRef } from "react";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { ChevronLeft } from "lucide-react";
import InputDatePicker from "../date-picker";

export interface BiltyFilterFormData {
  date: Date | undefined;
  pickUpCity: string;
  deliveryCity: string;
}

function BiltyFilterModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData.entries());
    console.log(data);
    onClose();
  };

  if (!isOpen) return null;

  const inputClasses: string =
    "w-full bg-neutral-50 border border-neutral-200 text-neutral-800";
  return (
    <div className="fixed inset-0 h-[100vh] m-0 bg-black bg-opacity-10 flex items-center justify-center z-50 p-10">
      <div
        ref={modalRef}
        className="bg-white rounded-lg shadow-lg w-full max-w-md animate-in fade-in duration-200"
      >
        <div className="flex items-center p-4 border-b">
          <button
            onClick={onClose}
            className="p-1 hover:bg-neutral-100 rounded-full"
          >
            <ChevronLeft className="h-5 w-5" />
          </button>
          <h2 className="text-xl font-semibold flex-1 text-center mr-6">
            Filter
          </h2>
        </div>

        <form onSubmit={handleSubmit} className="p-4 space-y-4">
          <div>
            <Label htmlFor="date" className="text-xs">
              Date
            </Label>
            <InputDatePicker />
          </div>
          <div>
            <Label htmlFor="pickUpCity" className="text-xs">
              Pick Up City
            </Label>
            <Input
              type="text"
              name="pickUpCity"
              id="pickUpCity"
              placeholder="Pick Up City"
              className={inputClasses}
            />
          </div>
          <div className="">
            <Label htmlFor="deliveryCity" className="text-xs">
              Delivery City
            </Label>
            <Input
              type="text"
              name="deliveryCity"
              placeholder="Delivery City"
              className={inputClasses}
            />
          </div>

          <Button
            type="submit"
            className="w-full bg-cardinal-500 hover:bg-cardinal-600 text-white py-3 rounded-lg"
          >
            APPLY
          </Button>
        </form>
      </div>
    </div>
  );
}

export default BiltyFilterModal;
