import { useState } from "react";
import { Pencil } from "lucide-react";
import KhataEditHistoryModal, {
} from "../modals/khata-edit-history-modal";

export enum KhataPaymentStatus {
  ToPay = "To Pay",
  ToAdvance = "To Advance",
}

export interface KhataHistoryCardProps {
  orderId: string;
  date: Date;
  amount: string; // change it to number if required
  paymentStatus: KhataPaymentStatus;
  reason: string;
}

export default function KhataHistoryCard({ ...props }: KhataHistoryCardProps) {
  const [showKhataEditHistoryModal, setShowKhataEditHistoryModal] =
    useState<boolean>(false);

  const toggleModal = () => {
    setShowKhataEditHistoryModal((prev) => !prev);
  };

  return (
    <div className="p-3 shadow rounded-md w-full flex flex-col justify-between gap-2 text-xs">
      <div className="flex justify-between items-center text-xs">
        <div className="flex items-center gap-2">
          <p className="font-semibold">{props.orderId}</p>
          <div className="border" />
          <p className="text-neutral-400">
            {props.date.getDate()}{" "}
            {props.date.toLocaleString("default", { month: "short" })}{" "}
            {props.date.getFullYear()}
          </p>
        </div>
        <div className="flex items-center gap-2">
          {props.paymentStatus === KhataPaymentStatus.ToPay ? (
            <p className="text-cardinal-600 font-bold">- ₹{props.amount}</p>
          ) : (
            <p className="text-green-600 font-bold">₹ {props.amount}</p>
          )}
          <p className="bg-blue-200 p-1.5 cursor-pointer rounded-full hover:bg-blue-200" onClick={toggleModal}>
            <Pencil
              size={8}
              className="w-2 h-2 text-blue-600"
              
            />
          </p>
        </div>
      </div>
      <div className="flex justify-between items-center text-xs">
        <div className="flex gap-2">
          <p className="text-neutral-400">Reason:</p>
          <p className="font-medium  truncate max-w-[7rem] sm:max-w-[10rem] md:max-w-[12rem] lg:max-w-[15rem]">
            {props.reason}
          </p>
        </div>
        <div>
          <p className="text-xs text-neutral-400">
            {props.paymentStatus === KhataPaymentStatus.ToPay
              ? "To Pay"
              : "To Advance"}
          </p>
        </div>
      </div>
      <KhataEditHistoryModal
        isOpen={showKhataEditHistoryModal}
        onClose={toggleModal}
        {...props}
      />
    </div>
  );
}
