import { IoChevronBack, IoCloseOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import LogoImage from "./logo-image";

function BusinessProfileParent({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <main
      className="min-h-screen relative bg-cover bg-center"
      style={{
        backgroundImage: "url(/login/image-10.png)",
      }}
    >
      <div className="absolute top-4 right-4">
        <Link to="/">
          <IoCloseOutline className="w-6 h-6 text-neutral-600 cursor-pointer" />
        </Link>
      </div>
      <div className="absolute top-4 left-4">
        <div onClick={handleGoBack}>
          <IoChevronBack className="w-6 h-6 text-neutral-600 cursor-pointer" />
        </div>
      </div>
      <div className="flex flex-col sm:flex-row pt-16 min-h-screen">
        <div className="flex justify-center items-center sm:place-items-start">
          <LogoImage height={100} />
        </div>
        {children}
      </div>
    </main>
  );
}

export default BusinessProfileParent;
