import BiltyAddItemModal from "../../components/app/modals/bilty-add-item-modal";
import BiltyTruckDetailsModal from "../../components/app/modals/bilty-truck-details-modal";
import { useState } from "react";
import { Button } from "../../components/ui/button";
import { Plus } from "lucide-react";
import BiltyItemDetailsCard, {
  ItemDetailsCardProps,
} from "../../components/app/cards/bilty-item-details-card";
import BiltyConsignorConsigneeForm from "../../components/app/forms/bilty-consignor-consignee-form";
import BiltyFrightDetailsForm from "../../components/app/forms/bilty-fright-details-form";
import BiltyInvoiceDetailsForm from "../../components/app/forms/bilty-invoice-details-form";

const BiltyConsignmentPage = () => {
  const [showTruckDetailsModal, setShowTruckDetailsModal] =
    useState<boolean>(false);

  const [showAddItemModal, setShowAddItemModal] = useState<boolean>(false);

  const toggleTruckDetailsModal = () => {
    setShowTruckDetailsModal((prev) => !prev);
  };

  const toggleAddItemModal = () => {
    setShowAddItemModal((prev) => !prev);
  };

  const itemDetails: ItemDetailsCardProps[] = [
    {
      itemName: "Masala Chai",
      packagingType: "Packet",
      quantity: 100,
      weight: 250,
      weightUnit: "grams",
      amount: 199.99,
      noOfArticle: 2,
    },
    {
      itemName: "Basmati Rice",
      packagingType: "Bag",
      quantity: 5,
      weight: 1000,
      weightUnit: "grams",
      amount: 299.0,
      noOfArticle: 2,
    },
  ];

  return (
    <div className="w-full px-5 mb-10">
      <div className="w-full my-4">
        <BiltyConsignorConsigneeForm />
      </div>
      <div className="my-4">
        <h3 className="font-semibold sm:text-lg">Items</h3>
        <div className="min-h-40 grid sm:grid-cols-2 gap-5 lg:grid-cols-3 items-center my-3">
          {itemDetails.map((item, index) => (
            <BiltyItemDetailsCard key={index} {...item} />
          ))}
          <div className="flex items-center justify-center min-h-32">
            <Button
              onClick={toggleAddItemModal}
              className="bg-cardinal-600 p-2.5 rounded-full text-white hover:bg-cardinal-600 shadow-none"
            >
              <Plus size={16} />
            </Button>
          </div>
        </div>
      </div>
      <div className="w-full py-4">
        <BiltyFrightDetailsForm />
      </div>
      <div className="w-full py-4">
        <BiltyInvoiceDetailsForm />
      </div>
      <div className="w-full flex justify-center items-center">
        <Button className="bg-cardinal-600 hover:bg-cardinal-600 text-sm sm:text-base w-full max-w-[16rem]">
          SUBMIT
        </Button>
      </div>
      <BiltyTruckDetailsModal
        isOpen={showTruckDetailsModal}
        onClose={toggleTruckDetailsModal}
      />
      <BiltyAddItemModal
        isOpen={showAddItemModal}
        onClose={toggleAddItemModal}
      />
      {/*
      {itemDetails.map((item, index) => (
        <ItemDetailsCard key={index} {...item} />
      ))} */}
    </div>
  );
}
export default BiltyConsignmentPage;