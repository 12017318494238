import { Input } from "../../ui/input";

export default function BiltyFrightDetailsForm() {
  const inputClasses: string =
    "w-full bg-neutral-50 border border-neutral-200 text-neutral-800";

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const data = {
      paymentStatus: formData.get("paymentStatus") as string,
      totalAmount: formData.get("totalAmount") as string,
      remaining: parseInt(formData.get("remaining") as string),
      advance: parseInt(formData.get("advance") as string),
    };
    console.log(data);
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-[56rem] space-y-4">
      <h3 className="font-semibold text-base">Freight Details</h3>

      <div className="flex items-center gap-4">
        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="paymentStatus"
            value="toPay"
            className="w-4 h-4 text-blue-600 cursor-pointer"
            defaultChecked
          />
          <span className="text-sm text-gray-700">To Pay</span>
        </label>

        <label className="flex items-center gap-2 cursor-pointer">
          <input
            type="radio"
            name="paymentStatus"
            value="advance"
            className="w-4 h-4 text-blue-600 cursor-pointer"
          />
          <span className="text-sm text-gray-700">Advance</span>
        </label>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="space-y-4">
          <Input
            type="number"
            required
            name="totalAmount"
            placeholder="Total Amount"
            className={inputClasses}
          />
          <Input
            type="number"
            required
            name="remaining"
            placeholder="Remaining"
            className={inputClasses}
          />
        </div>
        <div>
          <Input
            type="number"
            required
            name="advance"
            placeholder="Advance"
            className={inputClasses}
          />
        </div>
      </div>
    </form>
  );
}
